<template>
<div id="page" class="audit-ma-log">
  <el-row class="pmib-form-row row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-form-item prop="txt_keywords">
        <el-input v-model="form.txt_keywords" placeholder="操作人">
          <template #prepend>
            <i class="iconfont icon-sousuo" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="dateRange">
        <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList()">搜索</el-button>
        <el-button type="info" @click="reset()">重置</el-button>
      </el-form-item>
    </el-form>
  </el-row>
  <el-table :data="table.data" v-loading="table.loading" header-cell-class-name="table-cell">
    <el-table-column type="index" width="80" label="序号" align="center" />
    <el-table-column prop="txt_user_type" label="账户类型" align="center" >
      <template #default="scope">{{ scope.row.txt_user_type||"- -" }}</template>
    </el-table-column>
    <el-table-column prop="txt_login_name" label="登录账户" align="center">
      <template #default="scope">{{ scope.row.txt_login_name||"- -" }}</template>
    </el-table-column>
    <el-table-column prop="dt_create" label="操作时间" align="center">
      <template #default="scope">{{ scope.row.dt_create||"- -" }}</template>
    </el-table-column>
    <el-table-column prop="txt_ip" label="操作IP" align="center">
      <template #default="scope">{{ scope.row.txt_ip||"- -" }}</template>
    </el-table-column>
    <el-table-column prop="txt_menu" label="操作菜单" align="center" />
    <el-table-column prop="txt_operate" label="操作内容" align="center" />
    <el-table-column prop="txt_status" label="操作结果" align="center" />
    <el-table-column prop="txt_desc" label="状态描述" align="center" />
  </el-table>
  <pagination ref="pageRef" :total="table.total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
</div>
</template>

<script>
// 审计管理 - 审计日志
import { Pagination } from "components";
import { ElMessage } from 'element-plus';
import { getOperateLogsList } from "api/apis.js";
import { reactive, toRefs, ref, onMounted } from "vue";
export default {
  components: {
    Pagination
  },
  setup() {
    const pageRef = ref(null);
    const state = reactive({
      form: {
        txt_keywords: null,
        dateRange: [],
        page: 1,
        limit: 10
      },
      table: {
        total: 0,
        data: [],
        loading: false
      },
    })

    onMounted(() => {
      getList();
    })

    // 获取列表数据
    const getList = () => {
      state.table.loading = true;
      let param = {
        txt_keywords: state.form.txt_keywords,
        dt_from: (state.form.dateRange || []).length == 0 ? null : state.form.dateRange[0],
        dt_end: (state.form.dateRange || []).length == 0 ? null : state.form.dateRange[1],
        pages: state.form.page,
        pageSize: state.form.limit
      }

      // 去掉空数据
      let keys = Object.keys(param);
      for (let i = 0; i < keys.length; i++) {
        if ((param[keys[i]] || "") == "") {
          delete param[keys[i]];
        }
      }

      getOperateLogsList(param).then(response => {
        if (response.code == 200) {
          state.table.data = response.data;
          state.table.total = response.total;
        } else {
          ElMessage.error(response.msg);
          state.table.data = [];
          state.table.total = 0;
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    };

    // 重置表单，重新搜索
    const reset = () => {
      state.form = {
        txt_keywords: null,
        dateRange: [],
        page: 1,
        limit: 10
      };
      pageRef.value.pagination.pageSize = 10;
      pageRef.value.pagination.currentPage = 1;
      getList();
    }

    return {
      ...toRefs(state),
      reset,
      pageRef,
      getList
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table {
  height: calc(100% - 100px);
}
</style>
